.width__clz {
    text-align: center !important;
    background-color: #e9e9e9 !important;
    white-space: nowrap !important;
    color: #6a6a6a !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    white-space: nowrap !important;
    cursor: pointer !important;
}

.design-ways-swiper-wrapper__clz .swiper-wrapper {
    height: 36px !important;
}

.design-swiper__clz .swiper-button-prev {
    width: 20px !important;
    height: 5px !important;
    left: 0px !important;
    top: unset !important;
    margin-top: unset !important;
    color: black !important;
}

.design-swiper__clz .swiper-button-prev::after {
    font-size: 20px !important;
}

.design-swiper__clz .swiper-button-next {
    width: 20px !important;
    height: 5px !important;
    right: 0px !important;
    top: unset !important;
    margin-top: unset !important;
    color: black !important;
}

.design-swiper__clz .swiper-button-next::after {
    font-size: 20px !important;
}

.design-container-items__clz {
    display: grid;
    grid-template-columns: 0.5fr 1.5fr;
    height: 100%;
}

.design-tab-nav-tabs-active-link__clz.nav-link.active {
    color: rgb(50, 51, 51) !important;
    background-color: #e6e6e6 !important;
    border-color: unset !important;
    border: none !important;
    border-left: 6px solid rgb(50, 51, 51) !important;
}

.nav-link__clz.nav-link {
    color: #121212 !important;
    width: 100%;
    height: 44px;
    white-space: nowrap !important;
    cursor: pointer !important;
}

.switch-items__clz {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 10px;
}

.switch-items-with-sub-steps__clz {
    display: grid;
    grid-template-columns: 0.5fr 0.1fr 1fr;
    gap: 10px;
    align-items: center;
}

.languages-table__clz {
    font-size: 15px !important;
}

.languages-table__clz.table th {
    font-weight: normal !important;
    padding: 1px;
    max-height: 45px !important;
}

.final-message-table__clz.table th {
    font-weight: normal;
    padding: 1px;
    max-height: 55px !important;
}

.languages-table__clz table {
    width: 100%;
}

.languages-table__clz.table td {
    padding: 0.4rem !important;
}

.languages-table__clz thead tbody,
tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    height: 45px !important;
}

.languages-table__clz tbody {
    display: block;
    overflow: hidden auto;
    table-layout: fixed;
    max-height: 114px;
}

.languages-table__clz table td {
    padding: 0.5rem !important;
}

.custom-design-button__clz {
    height: 38px !important;
    width: 130px !important;
    min-width: unset !important;
    min-height: unset !important;
}

.main-content-width__clz {
    max-width: 400px;
}

.main-content-has-sub-step__clz {
    gap: 25px;
}
.swiper-button__clz:hover {
    border: none !important;
}

.slide-color__clz {
    background-color: rgb(50, 51, 51) !important;
    color: white !important;
}
