.button-style-select___clz {
    height: 36px !important;
    min-height: 30px !important;
}
.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}

#TablePagination {
    min-height: 64px !important;
    z-index: 1;
}
.Card-footer__clz {
}
