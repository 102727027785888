.div-notification__clz {
    width: 100%;
    background-color: white;
    border-radius: 9px;
    margin-bottom: 0.5%;
    padding: 0px !important;
}

.card-header__clz {
    padding-bottom: 2px !important;
    box-shadow: -1px 0px 6px #d5d1d1;
    height: 66px !important;
}

.card-footer__clz {
    background-color: transparent !important;
}

.content__clz {
    padding: 0.5%;
    background-color: transparent;
}
.icon-close-notification__clz {
    cursor: pointer;
    float: right;
}

.pointer__clz {
    cursor: pointer;
}

.row__clz {
    padding-right: 7px;
}

.modal-Header__clz {
    box-shadow: 0px 0px 0px #0000000f;
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    opacity: 1;
}
.invalid__clz .css-yk16xz-control {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
}

.form-group.text-danger {
    color: #495057 !important;
}

.input__clz {
    border-color: #f46a6a !important;
    border-style: solid;
    border-width: 1px;
    padding-right: calc(1.5em + 0.94rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f46a6a' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f46a6a' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.235rem) center;
    background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}

.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}

.title__clz {
    margin-bottom: 10px;
    font-size: 17px;
    color: black;
    background: #d9d9d9;
    height: 43px;
}

.badge__clz {
    border: 2px solid #cfcdcd;
    padding: 5px;
    margin-top: 5px;
    border-radius: 19px;
    width: 36px;
    height: 31px;
    box-shadow: 12px 12px 5px -2px rgb(199 182 182 / 69%);
    -webkit-box-shadow: 6px 3px 8px -2px rgb(199 182 182 / 69%);
}
.cercle {
    width: 64px;
    height: 40px;
    border-radius: 20px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 4px #00000029;
    border: 1px solid #000;

    opacity: 1;
}

.cercleactive {
    width: 64px;
    height: 40px;
    border-radius: 20px;
    background: black 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 4px #ffffff;
    color: red;

    border: 1px solid #000;
    opacity: 1;
}
.badge-success-service__clz {
    background: #34c38f 0% 0% no-repeat padding-box !important;

    font: normal normal normal 16px/21px Segoe UI !important;
    letter-spacing: 0px !important;
    color: black !important;
    width: 175px !important;
    height: 34px !important;
    text-align: center !important;
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
    white-space: nowrap;
    line-height: 1;
    display: inline-block;
    vertical-align: baseline;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.badge-warning__clz {
    color: #212529;
    background-color: #ffc107;
    width: 175px !important;
    height: 34px !important;
    text-align: center !important;
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
    white-space: nowrap;
    line-height: 1;
    display: inline-block;
    font: normal normal normal 12px/17px Segoe UI !important;
    vertical-align: baseline;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.line-1 {
    border-top: 1px solid rgba(0, 0, 0, 0.125) !important;
    padding-top: 11px;
    margin-top: 11px;
  
}
card-service__clz{
     width: 450px !important;
}
