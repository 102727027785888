input[type='radio'] {
  -webkit-appearance:none;
  width:15px;
  height:15px;
  border:1px solid black;
  border-radius:50%;
  outline:none;
  box-shadow:0 0 5px 0px white;
}


input[type='radio']:before {
  content:'';
  display:block;
  width:60%;
  height:60%;
  margin: 20% auto;    
  border-radius:50%;    
}
input[type='radio']:checked:before {
  background:black;
}
.style-input-select__clz{
    width: 200px !important
}
.style-button-order__clz.btn-secondary{
    color:transparent;
    background-color:transparent;
    border-color:transparent
}
.style-button-order__clz.btn-secondary:active{
    color:transparent;
    background-color:transparent;
    border-color:transparent
}

